import heremapsConfig from "./heremaps"

const mapTiles = {
  lantmateriet: {
    tiles: [
      `https://maptiles.naturkartan-cdn.se/tiles/sweden/EPSG3857/{z}/{x}/{y}.png`,
    ],
    tileSize: 256,
    type: `raster`,
    minZoom: 6.5,
    maxZoom: 22,
    // maxZoom: 13,
    bounds: [
      [11.0273686052, 55.3617373725],
      [23.9033785336, 69.1062472602],
    ],
    zoomFallbackTileset: `outdoor`,
  },
  outdoor: {
    tiles: [
      `https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=9106f29987fc41eda5246b5463ceeba2`,
    ],
    tileSize: 256,
    type: `raster`,
  },
  hybrid: {
    tiles: [
      `https://maps.hereapi.com/v3/base/mc/{z}/{x}/{y}/png8?style=explore.satellite.day&apiKey=${heremapsConfig.apiKey}`,
      // TODO: implement language params:
      // https://www.here.com/docs/bundle/raster-tile-api-migration-guide/page/README.html#5-language
    ],
    tileSize: 256,
    type: `raster`,
  },
}

const mapTileNames = Object.keys(mapTiles)

const mapTileNameDefault = mapTileNames[0]

export { mapTiles as default, mapTileNames, mapTileNameDefault }
