import React, { Suspense, lazy, useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import { reaction } from "mobx"
import styled from "styled-components"
import { get } from "lodash"
import turfBboxPolygon from "@turf/bbox-polygon"
import turfBooleanWithin from "@turf/boolean-within"
import turfBbox from "@turf/bbox"
import turfCenter from "@turf/center"
import {
  multiLineString as turfMultiLineString,
  featureCollection as turfFeatureCollection,
} from "@turf/helpers"
import ReactMapboxGl, { Layer, Source, Feature } from "react-mapbox-gl"
import throttle from "raf-throttle"
import "mapbox-gl/dist/mapbox-gl.css"

import MapA11y from "./MapA11y"
import MapAreas from "./MapAreas"
import MapTrails from "./MapTrails"
import MapPoints from "./MapPoints"
import MapMeasure from "./MapMeasure"
import MapLocation from "./MapLocation"

import mapboxConfig from "../config/mapbox"
import mapTiles from "../config/mapTiles"
import colors from "../theme/colors"
import getEmbedParams from "../utils/getEmbedParams"
import { filterGeojsonBy, splitGeojson } from "../utils/geojson"
import { useStore } from "../store"

const MapEditTrail = lazy(() => import(`./MapEditTrail`))
const MapEditArea = lazy(() => import(`./MapEditArea`))
const MapEditPlace = lazy(() => import(`./MapEditPlace`))

const isSafari = !!window.navigator.userAgent.match(
  /^((?!chrome|android).)*safari/i
)

const isFirefox = window.navigator.userAgent.match(/firefox/i)

// eslint-disable-next-line new-cap
const MapGL = ReactMapboxGl({
  accessToken: mapboxConfig.accessToken,
  preserveDrawingBuffer: isSafari || isFirefox, // allows map printing in Safari/Firefox
})

const filterGeojson = (geojson) => {
  if (!geojson) return null

  const preselectedCategories = getEmbedParams(`preselectedCategories`)
  if (preselectedCategories) {
    return filterGeojsonBy(geojson, {
      categories: preselectedCategories.replace(`!`, ``).split(`,`),
      // categoriesAllBut: preselectedCategories.startsWith(`!`),
    })
  } else {
    return geojson
  }
}

let pointsClusterMouseEnterId = null

const Map = () => {
  const store = useStore()
  const [mapTilesetName, setMapTilesetName] = useState(store.mapStyle)
  const edit = getEmbedParams(`edit`)

  const [geojson, geojsonPoints] = useMemo(() => {
    return splitGeojson(filterGeojson(store.geojson))
  }, [store.geojson])

  const manageMapTilesetName = () => {
    let name = store.mapStyle
    const tileset = mapTiles[name]

    if (tileset.minZoom && tileset.minZoom >= store.zoom)
      name = tileset.zoomFallbackTileset
    if (tileset.maxZoom && tileset.maxZoom <= store.zoom)
      name = tileset.zoomFallbackTileset

    if (name == store.mapStyle && tileset.bounds) {
      const mapBounds = store.map.getBounds()
      const mapBoundsPolygon = turfBboxPolygon([
        mapBounds._ne.lng,
        mapBounds._ne.lat,
        mapBounds._sw.lng,
        mapBounds._sw.lat,
      ])

      const tilesetBoundsPolygon = turfBboxPolygon(tileset.bounds.flat())
      if (!turfBooleanWithin(mapBoundsPolygon, tilesetBoundsPolygon))
        name = tileset.zoomFallbackTileset
    }

    setMapTilesetName(name)
  }

  const manageMapTilesetNameThrottled = throttle(manageMapTilesetName, 1000)

  const [center, zoom, bounds] = useMemo(() => {
    let center = undefined
    let zoom = undefined
    let bounds = undefined
    const preselectedSiteId = getEmbedParams(`preselectedSiteId`)

    if (store.zoom) {
      zoom = [store.zoom]

      if (preselectedSiteId && store.geojson) {
        const feature = store.geojson.features.find(
          (f) => f.id === preselectedSiteId
        )

        if (feature) {
          const relatedId = get(feature, `properties.children[0]`)

          if (relatedId) {
            const relatedFeat = store.geojson.features.find(
              (f) => f.id === relatedId
            )

            if (relatedFeat)
              center = turfCenter(relatedFeat).geometry.coordinates
          }

          if (!center) center = turfCenter(feature).geometry.coordinates
        }
      }

      if (!center && store.geojson)
        center = turfCenter(store.geojson).geometry.coordinates
    }

    const embedCenter = getEmbedParams(`center`)
    if (embedCenter) {
      center = embedCenter.split(`,`)
    }

    if (!center && store.editData) {
      if (store.editData.trail) {
        bounds = turfBbox(turfMultiLineString(store.editData.trail))
      } else if (store.editData.area) {
        bounds = turfBbox(turfMultiLineString(store.editData.area))
      } else if (store.editData.place) {
        center = store.editData.place
      }
    }

    if (!center) {
      if (store.geojson) {
        const feature =
          preselectedSiteId &&
          store.geojson.features.find((f) => f.id === preselectedSiteId)

        if (feature) {
          const relatedId = get(feature, `properties.children[0]`)
          const feats = [feature]

          if (relatedId) {
            const relatedFeat = store.geojson.features.find(
              (f) => f.id === relatedId
            ) // eslint-disable-line prettier/prettier
            if (relatedFeat) feats.push(relatedFeat)
          }

          bounds = turfBbox(turfFeatureCollection(feats))
        } else {
          bounds = turfBbox(store.geojson)
        }
      } else {
        center = [14.27094, 62.42884] // Sweden
      }
    }

    return [center, zoom, bounds]
  }, [])

  const bindMapClick = () => {
    let proceed = false

    store.map.on(`click`, (e) => {
      proceed = !e.defaultPrevented
    })

    document.addEventListener(
      `click`,
      (e) => {
        if (!proceed) return
        if (e.defaultPrevented) return
        if (!e.screenX && !e.screenY) return
        // store.setMapPointActive(null);
        store.setPointCardData(null)
      },
      { passive: true }
    )
  }

  const pointsClusterClick = (e) => {
    if (store.measureActive) return
    if (e.defaultPrevented) return
    if (!e.features.length) return
    e.preventDefault()

    store.setPointCardData(null)
    const feature = e.features[0]

    store.map
      .getSource(`geojson-points`)
      .getClusterExpansionZoom(feature.properties.cluster_id, (err, zoom) => {
        if (err) return
        store.map.easeTo({
          center: feature.geometry.coordinates,
          zoom: zoom,
        })
      })
  }

  const pointsClusterMouseEnter = (e) => {
    if (!e.features.length) return
    if (store.measureActive) return

    const feature = e.features[0]

    if (pointsClusterMouseEnterId !== feature.id) pointsClusterMouseLeave()

    store.map.setFeatureState(
      { id: feature.id, source: `geojson-points` },
      { hover: true }
    )
    store.map.getCanvas().style.cursor = `pointer`
    pointsClusterMouseEnterId = feature.id
  }

  const pointsClusterMouseLeave = (e) => {
    if (!pointsClusterMouseEnterId) return

    store.map.setFeatureState(
      { id: pointsClusterMouseEnterId, source: `geojson-points` },
      { hover: false }
    )
    store.map.getCanvas().style.cursor = null
    pointsClusterMouseEnterId = null
  }

  const featureMouseEnter = (e) => {
    if (pointsClusterMouseEnterId) return
    if (!e.features.length) return
    if (isFeatureInvisible(e.features[0])) return

    featureMouseLeave()
    const pointId = getFeatPointId(e.features[0])
    store.setMapPointHovered(pointId)
  }

  const featureMouseLeave = () => {
    if (store.mapPointHovered) {
      store.setMapPointHovered(null)
    }
  }

  const featureClick = (e) => {
    if (store.measureActive) return
    if (e.defaultPrevented) return
    if (!e.features.length) return
    if (isFeatureInvisible(e.features[0])) return
    e.preventDefault()

    const pointId = getFeatPointId(e.features[0])
    store.setMapPointActive(pointId)
  }

  const setPreselectedSite = () => {
    const pointId = getEmbedParams(`preselectedSiteId`)
    if (!pointId) return

    const feature = store.geojson.features.find((f) => f.id === pointId)
    if (!feature) return

    store.setMapPointActive(pointId, false, false)
  }

  const setPreselectedCategories = () => {
    const names = (getEmbedParams(`preselectedCategories`) || ``).split(`,`)
    if (!names.length || !store.geojson) return

    // filter out category names that are not in geojson
    const verified = []
    store.geojson.features.forEach(
      (feature) =>
        feature.properties.categories &&
        feature.properties.categories.forEach(
          (category) =>
            names.includes(category.slug) &&
            !verified.includes(category.slug) &&
            verified.push(category.slug)
        )
    )

    store.setCategoriesActive(verified, false)

    // let categories = []
    // if (preselected.startsWith(`!`)) {
    //   // all, but...
    //   const preselectedArr = preselected.split(`,`)
    //   store.categories.forEach(
    //     (c) => !preselectedArr.includes(c.slug) && categories.push(c.slug)
    //   )
    // } else {
    //   categories = preselected.split(`,`)
    // }

    // store.setCategoriesActive(categories, false)
  }

  const getFeatPointId = (feature) => {
    return feature.layer.id === `points`
      ? feature.id
      : feature.properties.parent
  }

  const isFeatureInvisible = (feature) => {
    return (
      store.map.getFeatureState({
        id: feature.id,
        source: `geojson-points`,
      }).visible == `no` ||
      store.map.getFeatureState({
        id: feature.id,
        source: `geojson`,
      }).visible == `no`
    )
  }

  const zoomEnd = () => {
    if (!store.map) return
    store.setZoom(store.map.getZoom())
  }

  const toggleFeatVisibility = () => {
    const hasClusters = !!store.map.querySourceFeatures(`geojson-points`, {
      filter: [`has`, `point_count`],
    }).length

    const points = store.map
      .querySourceFeatures(`geojson-points`, {
        filter: [`!`, [`has`, `point_count`]],
      })
      .map((f) => f.id)
      .reduce((u, i) => (u.includes(i) ? u : [...u, i]), [])

    store.map
      .querySourceFeatures(`geojson`)
      .forEach((feature) =>
        store.map.setFeatureState(
          { id: feature.id, source: `geojson` },
          { visible: hasClusters ? `no` : `yes` }
        )
      )

    if (hasClusters) {
      store.map
        .querySourceFeatures(`geojson`, {
          filter: [`any`, ...points.map((id) => [`==`, `parent`, id])],
        })
        .forEach((feature) =>
          store.map.setFeatureState(
            { id: feature.id, source: `geojson` },
            { visible: `yes` }
          )
        )
    }
  }

  const toggleFeatVisibilityThrottled = throttle(toggleFeatVisibility)

  const mapLoaded = (map) => {
    store.setMap(map)

    // const isTouchEvent = (e) => e.originalEvent && `touches` in e.originalEvent
    // const isTwoFingerTouch = (e) => e.originalEvent.touches.length >= 2

    // map.on(`dragstart`, (event) => {
    //   if (isTouchEvent(event) && !isTwoFingerTouch(event)) {
    //     map.dragPan.disable()
    //   }
    // })

    // map.on(`touchstart`, (event) => {
    //   if (isTouchEvent(event) && isTwoFingerTouch(event)) {
    //     map.dragPan.enable()
    //   }
    // })

    if (getEmbedParams(`scrollZoom`) === false) {
      store.map.scrollZoom.disable()
    }

    if (bounds) {
      // because MapGL -> fitBoundsOptions -> maxZoom doesn't work
      store.map.fitBounds(bounds, {
        padding: window.innerWidth > 110 &&
          window.innerHeight > 130 && {
            top: 90,
            bottom: 35,
            left: 50,
            right: 50,
          },
        duration: 0,
        maxZoom: 14,
      })
    }

    manageMapTilesetName()

    store.map.on(`move`, manageMapTilesetNameThrottled)

    store.map.on(`data`, toggleFeatVisibilityThrottled)
    store.map.on(`move`, toggleFeatVisibilityThrottled)

    store.map.on(`click`, `points-cluster`, pointsClusterClick)
    store.map.on(`mousemove`, `points-cluster`, pointsClusterMouseEnter)
    store.map.on(`mouseleave`, `points-cluster`, pointsClusterMouseLeave)

    store.map.on(`mousemove`, `points`, featureMouseEnter)
    store.map.on(`mouseleave`, `points`, featureMouseLeave)
    store.map.on(`mouseenter`, `trails`, featureMouseEnter)
    store.map.on(`mouseleave`, `trails`, featureMouseLeave)
    store.map.on(`mouseenter`, `areas`, featureMouseEnter)
    store.map.on(`mouseleave`, `areas`, featureMouseLeave)

    store.map.on(`click`, `points`, featureClick)
    store.map.on(`click`, `trails`, featureClick)
    store.map.on(`click`, `areas`, featureClick)

    bindMapClick()
    setPreselectedSite()
    setPreselectedCategories()
    zoomEnd()
  }

  useEffect(() => {
    const reactionMapZoom = reaction(
      () => store.zoom,
      () => {
        if (store.map && store.map.getZoom() !== store.zoom) {
          store.map.flyTo({ zoom: store.zoom })
        }
      }
    )

    const reactionMapStyle = reaction(
      () => store.mapStyle,
      () => {
        setMapTilesetName(store.mapStyle)
      }
    )

    return () => {
      reactionMapZoom()
      reactionMapStyle()
    }
  }, [])

  return (
    <Container>
      <MapGL
        containerStyle={{
          width: `100%`,
          height: `100%`,
          position: `relative`,
          zIndex: 1,
        }}
        onStyleLoad={mapLoaded}
        onZoomEnd={zoomEnd}
        style={{
          version: 8,
          sources: {},
          sprite: `https://om-map-cdn.b-cdn.net/sprite/sprite`,
          glyphs: `https://om-map-cdn.b-cdn.net/glyphs/{fontstack}/{range}.pbf`,
          layers: [],
        }}
        zoom={zoom}
        center={center}
      >
        <Layer id="base" />

        {Object.entries(mapTiles).map(([key, options]) => (
          <React.Fragment key={key}>
            {key === mapTilesetName && (
              <>
                <Source
                  id="tileset"
                  geoJsonSource={{
                    type: options.type,
                    tiles: options.tiles,
                    tileSize: options.tileSize,
                  }}
                />

                <Layer before="base" sourceId="tileset" type={options.type} />
              </>
            )}
          </React.Fragment>
        ))}

        <Source
          id="geojson"
          geoJsonSource={{
            type: `geojson`,
            data: geojson,
          }}
        />

        <Source
          id="geojson-points"
          geoJsonSource={{
            type: `geojson`,
            data: geojsonPoints,
            // cluster: !edit,
            cluster: false,
            clusterMaxZoom: 14,
            clusterMinPoints: 4,
            clusterRadius: 40,
          }}
        />

        <MapAreas />

        <MapTrails />

        <MapPoints />

        {store.map && <MapA11y />}

        {store.measureActive && <MapMeasure />}

        {store.elevationsPointActive && (
          <Layer
            type="circle"
            paint={{
              "circle-radius": 5,
              "circle-opacity": 1,
              "circle-color": colors.orange,
              "circle-stroke-width": 2,
              "circle-stroke-color": colors.black,
            }}
          >
            <Feature coordinates={store.elevationsPointActive} />
          </Layer>
        )}

        {store.location && (
          <MapLocation
            coordinates={[
              store.location.coords.longitude,
              store.location.coords.latitude,
            ]}
          />
        )}

        <Suspense fallback={null}>
          <React.Fragment>
            {edit === `trail` && store.map && <MapEditTrail />}

            {edit === `area` && store.map && <MapEditArea />}

            {edit === `place` && store.map && <MapEditPlace />}
          </React.Fragment>
        </Suspense>
      </MapGL>
    </Container>
  )
}

export default observer(Map)

const Container = styled.section`
  width: 100%;
  height: 100%;
  position: relative;

  .mapboxgl-canvas:focus {
    outline-offset: -2px;
  }

  .mapboxgl-ctrl-attrib.mapboxgl-compact {
    margin: 0;
  }

  .mapboxgl-ctrl-bottom-left {
    display: none;
  }
`
